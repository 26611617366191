*, 
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}



:root {
  --ff-primary: 'Epilogue', sans-serif;;
  --ff-secondary: 'Source Code Pro', monospace;
  --ff-display: 'Reem Kufi', sans-serif;

  --fw-reg: 300;
  --fw-bold: 900;

  --clr-light: #fff;
  --clr-dark: #4b4a4a;
  --clr-accent: #ff82e4;
  --clr-bkground: #fff1d9;
  --clr-blush: #ffa08e; 
  --clr-ltmango: #ffba7a;
  /* --clr-bkground: #fac4a5; */
  --clr-second: #fab68e;

  --fs-h1: 9rem;
  --fs-h2: 2.25rem;
  --fs-h3: 1.25rem; 
  --fs-body: 1rem;

  --bs: 0.25em 0.25em 0.75em rbga(0,0,0,.25);
}

@media (min-width: 800px) {
    :root {  
    --fs-h1: 9.75rem;
    --fs-h2: 3.75rem;
    --fs-h3: 1.75rem;
    --fs-h4: 1rem;
    --fs-body: 1rem;
    }
}

html {
    scroll-behavior: smooth;
    font-size: 62.5%
}

body { 
  color: var(--clr-blush);
  margin: 0;
  font-size: var(--fs-body);
  line-height: 1.4rem;
}

strong {
  font-weight: var(--fw-bold)
}


.portfolio-container {
  position: relative;
  min-height: 100vh;
  width: 100%
}

.content-wrap {
 padding-bottom: 6rem;
}

a {
  color: var(--clr-second)
}

.navbar{
  font-size: 2em;
  background: papayawhip;
  text-align: right;
  font-weight: 800;
  margin-right: 2rem;
  margin-top: 1rem;
}


/* Home Page Styles  */


.intro-header {
  /* position: relative; */
  text-align: center;
}

.intro{
  /* position: absolute; */
  /* left: 50%; */
  /* transform: translate(-50%, 15%) */
}

h1.intro--title {
  color: var(--clr-blush);
  font-family: var(--ff-primary);
  font-size: 14rem;
  font-weight: 800;
  text-align: center;
  line-height: 1.2;
  backface-visibility: hidden;
}

.intro--title--first {
  display: block;
  animation: moveInLeft 1s ease-out 
}

.intro--title--second {
  display: block;
  animation: moveInRight 1s ease-out
}

.intro--subtitle {
  font-family: var(--ff-primary);
  color: var(--clr-blush);
  font-size: 2em;
  line-height: 1.9;
  text-align: center;
  animation: moveInBottom 1s ease-out;
  animation-delay: 1s;
  animation-fill-mode: backwards;
}


@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-100px)
  }

  100% {
    opacity: 1;
    transform: translate(0)
  }
}

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(100px)
  }

  100% {
    opacity: 1;
    transform: translate(0)
  }
}

@keyframes moveInBottom {
  0% {
      opacity: 0;
      transform: translateY(100px)
  }

  100% {
      opacity: 1;
      /* translate(0) makes it look the way it is originally coded to look */
      transform: translate(0);
  }
}

@media (max-width: 767px) {
  .intro-header {
    padding: 2%;
  }
}

/* Btn Styles */

.btn { 
  border-width: .5rem;
  border-style: solid;
  background-color: papayawhip;
  border-radius: .5rem;
  border-width: .35rem;
  border-style: solid;
  border-color:var(--clr-blush);
  color: var(--clr-blush);
  font-size: 1.6rem;
  text-transform: lowercase;
  margin: 1rem 1rem 1rem 1rem;
  padding: 1rem 2rem;
}

.btn:link, 
.btn:visited {
  text-decoration: none;
  display: inline-block;
  transition: all .2s;
  position: relative;
  cursor: pointer;
}

.btn:hover {
  transform: translateY(-3px);
  /* background: var(--clr-second);
  color: white; */
  color: papayawhip;
  background: var(--clr-second);
  /* background: linear-gradient(to right bottom, #ffc38b, #f5752b); */
  box-shadow: 0 .2rem 1rem rgba(255, 181, 42, 0.89)
}

.btn:active { 
  transform: translateY(-1px);
  box-shadow: 0 .1rem .5rem rgba(0, 0, 0, 0.2);
}

.btn-home {
  cursor: pointer;
}

.btn-animated {
  animation-name: moveInBottom;
  animation-duration: .5s;
  animation-delay: .75s;
  animation-timing-function: ease-out;
  /* automatically applies the styles of the 0% animation */
  animation-fill-mode: backwards;

}

img { 
    display: block;
    max-width: 100%;
}

h1, h2, h3 {
    line-height: 1; 
}

/* Tech Skills */

h2.technical-skills{
  font-size: var(--fs-h2);
  text-align: center;
  font-family: var(--ff-primary);
  color: var(--clr-blush);
 
}

.tech-img-container {
  text-align: center;
}

.tech-skill{
  display: inline-block;
}

/* About Page */

.about {
  align-content: center;
  margin-top: 5%; 
}

.about-container { 
  max-width: 100%;
  margin-bottom: 8rem;
  display: flex;
}

.img-container {
  vertical-align: top;
  margin-left: 15%;
  display: inline-block;
  animation: moveInLeft 1s ease-out;
  animation-delay: .5s;
  animation-fill-mode: backwards;
}

.about-me-container {
  width: 50%;
  display: inline-block;
  vertical-align: top;
}

h2.about-me {
  font-size: 4.5em;
  font-family: var(--ff-primary);
  font-weight: 800;
  color: var(--clr-blush);
  animation: moveInNormal 1s ease-out;
  animation-delay: 1s;
  animation-fill-mode: backwards;

}

p.about-me {
  font-size: var(--fs-h3);
  margin-right: 6em;
  color: var(--clr-dark);
  animation: moveInRight 1s ease-out;
  animation-delay: 1s;
  animation-fill-mode: backwards;
}

@keyframes moveInNormal {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    transform: translate(0)
  }
}

@media (max-width: 767px) {

  .about-container {
    padding: 5%;
    flex-direction: column;
    align-items: center;
  }
  .img-container,
  .about-me-container {
    width: 100%
  }

  .about-me-container {
    margin-top: 5%;
    margin-left: 20%
  }
}


/* Work / Technical Projects Styles  */

.my-work{
  padding: 2%;
  margin-top: 40px;
}

h2.section__title--work {
  font-size: 5rem;
  text-align: center;
  font-family: var(--ff-primary);
  color: var(--clr-blush);
  animation: moveInLeft 1s ease-in;
  font-weight: 800;
}

/* .section__subtitle--work { 
  font-size: var(--fs-h2);
  text-align: center;
  font-family: var(--ff-primary);
  color: var(--clr-blush);
  font-weight: 800;
  animation: moveInLeft 1s ease-in;
} */

.project-container {
  max-width: 100%;
  align-items: center;
  padding: 1rem;
  display: flex;
  margin-top: 8rem;
}

.projectimage-container { 
  width: 50%;
  align-items: center;
  display: inline-block;
  vertical-align: top;
  animation: moveInLeft 2s ease-in;
}

.projectimage-container:hover { 
  transform: translateY(-5px);
  box-shadow: 0 .5rem .5rem rgba(29, 23, 28, 0.89)
}

.projectimage { 
  max-width: 90rem;
  max-height: 41rem;
}

.description-container {
  width: 50%;
  display: inline-block;
  vertical-align: top;
  align-items: center;
  margin-left: 6rem;
  animation: moveInRight 2s ease-in;
}

.project-description {
  font-size: 1.35rem;
  font-family: var(--ff-primary);
  color: var(--clr-dark); 
  /* margin-left: 4rem; */
}

.project-title { 
  font-size: 1.45rem;
  font-weight: bold;
  color: var(--clr-dark);
  display: inline-block;
}

.btn-container { 
  display: block;
}

@media (max-width: 900px) {
  .project-container {
    flex-direction: column;
  }
  .projectimage-container,
  .description-container {
    width: 100%;
  }

  .description-container {
    margin-left: 1.25rem;
    margin-right: 1rem;
  }

  .project-description { 
    padding-top: 1rem;
  }

  .btn-container {
    margin: 0
  }
}


/* footer styles */

.footer {
  display: block;
  background-color: var(--clr-ltmango);
  color: var(--clr-bkground);
  text-align: center;
  margin-top: 3rem;
  padding: 2rem;
  font-size: var(--fs-h3);
  position: absolute; 
  width: 100%;
}

.footer a { 
  color: var(--clr-bkground);
  text-decoration: none;
}

.footer a:hover { 
  color: var(--clr-accent)
}

.footer__link {
  font-weight: var(var(--fw-bold));
}

.footer__link:hover, 
.social-list__link:hover {
  color: var(--clr-light);
  opacity: .7;
}

.footer__link:hover { 
  color: var(--clr-light);
  text-decoration: underline;
}

.social-list {
  list-style: none;
  display: flex;
  justify-content: center;
  margin: 2em 0 0;
  padding: 0
}

.social-list__item {
  margin: 0 .5em; 
}

.social-list__link {
  padding: .5em;
}